<template>
    <div class="row">
        <div class="col-12">
            <h4 class="mt-2 pt-50">My Role Permissions</h4>
            <!-- Permission table -->
            <div class="table-responsive">
                <table class="table table-flush-spacing">
                    <tbody>
                        <tr v-for="item in permissions" :key="item">
                            <td class="text-nowrap fw-bolder">{{ item }}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>


</template>


<script>
export default {
    name: "AccountSettingPermissions",


    data() {
        return {
            AddPermissions: [],
            SelectedRole: {},
        };
    },

    props: {
        permissions: {
            type: Object,
            default: () => [],
        },
    },

    mounted() {
       console.log(this.permissions)
    },
}
</script>