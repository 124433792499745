<template>
  <div class="content-box">
    <div class="d-flex justify-content-center">
      <b-spinner
        variant="success"
        label="Spinning"
        v-if="showSpinner"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      v-if="!showSpinner"
    >
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>

        <account-setting-general :general-data="options" />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab :active="(isAccountSettingRoute && (activeTab == undefined || activeTab == null))">
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <account-setting-password />
      </b-tab>
      <!--/ change password tab -->

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">My Permissions</span>
        </template>

        <account-setting-permissions :permissions="permissions" />
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab v-if="role === 'admin'">
        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Audit Logs</span>
        </template>

        <account-setting-information :information-data="logs" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Units of Measure</span>
        </template>

        <account-setting-uom />
      </b-tab>
      <b-tab :active="(activeTab == 'facebook')">
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">My Facebook</span>
        </template>

        <AccountAppid />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UploadIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Upload / Download Center</span>
        </template>
        <UploadCenter />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import api from "@/api/api-v1-config";
import { BTabs, BTab, BSpinner } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";
import AccountSettingPermissions from "./AccountSettingPermissions.vue";
import AccountSettingUom from "./AccountSettingUOM.vue";
import AccountAppid from "./AccountAppid.vue";
import UploadCenter from "./UploadCenter.vue";
import DownloadCenter from "./DownloadCenter.vue";
import { concat } from "lodash";

export default {
  components: {
    BTabs,
    BTab,
    BSpinner,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    AccountSettingPermissions,
    AccountSettingUom,
    AccountAppid,
    UploadCenter,
    DownloadCenter,
  },
  data() {
    return {
      token: localStorage.getItem("accessToken"),
      role: JSON.parse(localStorage.getItem("userData")).role,
      activeTab: this.$route.meta.activeTab,
      showSpinner: true,
      options: [],
      logs: [],
      permissions: [],
      isAccountSettingRoute: false,
    };
  },
  methods: {
    async getProfile() {
      let conf = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      };
      await api
        .get("/personal/profile", conf)

        .then((response) => {
          this.options = response.data.result;
          this.showSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSpinner = false;
        });
    },

    async getLogs() {
      let conf = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      };
      await api
        .get("/personal/logs", conf)

        .then((response) => {
          this.logs = response.data;

          this.showSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSpinner = false;
        });
    },

    getFromLocalStorage() {
      var userData = JSON.parse(localStorage.getItem("userData"));

      if (userData.has_default_password) {
        this.isAccountSettingRoute = true;
      } else {
        this.isAccountSettingRoute = false;
      }
    },

    async getPermissions() {
      let conf = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      };
      await api
        .get("/personal/permissions", conf)

        .then((response) => {
          this.permissions = response.data.result;
          console.log(this.permissions);

          this.showSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSpinner = false;
        });
    },
  },

  mounted() {
    console.log('fuck: ', this.$route);
    this.getFromLocalStorage();
    this.getProfile();
    this.getLogs();
    this.getPermissions();
  },
};
</script>
