<template>
  <div>
    <b-row>
      <b-col lg="12" class="d-flex justify-content-end mx-0">
        <b-button-group class="mb-3">
          <b-button v-for="(category, index) in categories" :key="index" variant="outline-primary"
            @click="selectedCategory = category" :class="{ active: selectedCategory === category }">
            {{ category }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!--Lavbour table-->
    <div v-if="selectedCategory === 'Labour'">
      <b-row>
        <b-col>
          <b-button variant="success" class="mb-1 float-right" @click="downloadExcel">
            <feather-icon icon="DownloadIcon" /> Download List
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="labourList"
            :fields="fieldsLabour">

            <template #cell(costPerHour)="data">
              Rs {{ data.item.costPerHour.toFixed(2) }}
            </template>
          </b-table>
          <p variant="warning">Export Excel for the full list</p>
        </b-col>
      </b-row>
    </div>


    <div v-if="selectedCategory === 'Equipment'">
      <b-row>
        <b-col>
          <b-button variant="success" class="mb-1 float-right" @click="downloadExcel">
            <feather-icon icon="DownloadIcon" /> Download List
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="equipmentList"
            :fields="fieldsEquipments">
          </b-table>
          <p variant="warning">Export Excel for the full list</p>
        </b-col>
      </b-row>
    </div>

    <div v-if="selectedCategory === 'Category'">
      <b-row>
        <b-col>
          <b-button variant="success" class="mb-1 float-right" @click="downloadExcel">
            <feather-icon icon="DownloadIcon" /> Download List
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="categoryList"
            :fields="fieldsCategory">
          </b-table>
          <p variant="warning">Export Excel for the full list</p>
        </b-col>
      </b-row>
    </div>

    <div v-if="selectedCategory === 'Supplier'">
      <b-row>
        <b-col>
          <b-button variant="success" class="mb-1 float-right" @click="downloadExcel">
            <feather-icon icon="DownloadIcon" /> Download List
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="supplierList"
            :fields="fieldsSuppliers">
          </b-table>
          <p variant="warning">Export Excel for the full list</p>
        </b-col>
      </b-row>
    </div>

    <!-- <div v-if="selectedCategory === 'Product'">
      <b-row>
        <b-col>
          <b-button variant="success" class="mb-1 float-right" @click="downloadExcel">
            <feather-icon icon="DownloadIcon" /> Download List
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="productList"
            :fields="fieldsProduct">
          </b-table>
          <p variant="warning">Export Excel for the full list</p>
        </b-col>
      </b-row>
    </div> -->

  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import {
  BButton,
  BButtonGroup,
  BTable,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api-v2-config";

export default {
  components: {
    BButton,
    BButtonGroup,
    BTable,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      selectedCategory: "Labour",
      categories: ["Labour", "Equipment", "Category", "Supplier", "Product"],

      fieldsLabour: [
        { key: "labourName", label: "Labour Type" },
        "quantity",
        { key: "costPerHour", label: "Cost Per Hour (Rs)" },
      ],


      fieldsSuppliers: [
        { key: "name", label: "Supplier Name" },
        { key: "email", label: "Email" },
        { key: "address", label: "Address", sortable: true },
        { key: "phoneNumber", label: "Phone Number" },
        { key: "goodsSupplied", label: "Goods Supplied", sortable: true },
      ],
      fieldsCategory: [{ key: "name", label: "Category Name" }],
      fieldsEquipments: [
        { key: "assetCode", label: "Equipment Name" },
        { key: "name", label: "Equipment Code", sortable: true },
      ],
      fieldsProduct: [
        { key: "name", label: "Product Name" },
        { key: "code", label: "Product Code", sortable: true },
      ],
    };
  },

  computed: {
    ...mapGetters("labourModule", {
      labourList: "labour",
      loading: "loading",
    }),
    ...mapGetters("itemcategoryModule", {
      categoryList: "itemcategories",
      loading: "loading",
    }),
    ...mapGetters("equipmentModule", {
      equipmentList: "equipments",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      supplierList: "suppliers",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      productList: "product",
      loading: "loading",
    }),
  },

  methods: {

    ...mapActions("labourModule", [
      "getLabourListAction",
    ]),
    ...mapActions("itemcategoryModule", [
      "getItemCategoryListAction",
    ]),
    ...mapActions("equipmentModule", ["getEquipmentListAction"]),
    ...mapActions("supplierModule", [
      "getSupplierListAction",
    ]),
    ...mapActions("productModule", ["getProductListAction"]),

    convertJsonToCsv(jsonData, filename) {
      const keys = Object.keys(jsonData[0]);
      const csvContent = [
        keys.join(','),  // header
        ...jsonData.map(obj => keys.map(key => obj[key]).join(','))  // data rows
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");

      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${filename}.csv`);
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async downloadExcel() {
      let apiEndpoint = '';
      const categoryApiMapping = {
        'Labour': 'labours/download',
        'Equipment': 'equipments/download',
        'Category': 'ItemCategories/Download',
        'Supplier': 'suppliers/download',
        'Product': 'products/download',
      };
      apiEndpoint = categoryApiMapping[this.selectedCategory];

      // Trigger download
      try {
        const response = await api.get(apiEndpoint);
        if (response.data && response.data.value && Array.isArray(response.data.value.result)) {
          this.convertJsonToCsv(response.data.value.result, this.selectedCategory);
        }

      } catch (error) {
        console.error('An error occurred:', error);
      }
    },

  },

  async mounted() {
    await this.getLabourListAction()
    await this.getItemCategoryListAction()
    await this.getEquipmentListAction()
    await this.getSupplierListAction()
    await this.getProductListAction()
  },
};
</script>
