<template>
  <div>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">

      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    <b-table :items="informationData" :fields="fields" striped responsive :per-page="perPage" :current-page="currentPage"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
      :filter-included-fields="filterOn" @filtered="onFiltered">
      <template #cell(show_details)="row">
        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col md="12" class="mb-1" v-if="row.item.oldValues == null">
              <strong>Old Values: </strong>No Old Values
            </b-col>
            <b-col md="12" class="mb-1" v-if="row.item.oldValues !== null">
              <strong>Old Values: </strong>
              <b-table :items="beautifyJson(row.item.oldValues)" :fields="['key', 'value']" bordered></b-table>
            </b-col>
            <b-col md="12" class="mb-1">
              <strong>New Values: </strong>
              <b-table :items="beautifyJson(row.item.newValues)" :fields="['key', 'value']" bordered></b-table>
           
            </b-col>

          </b-row>
         
        </b-card>
      </template>
      
    </b-table>
    
    <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
      </b-col>

      </b-row>

  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BPagination, BInputGroupAppend, BInputGroup, BFormSelect, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
  },
  props: {
    informationData: {
      type: Array,
      default: () => { },
    },
  },
  data() {
    return {
      fields: [
        'show_details',
        "userName",
        { key: "tableName", sortable: true },
        { key: "dateTime", sortable: true }
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },

  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.informationData.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    beautifyJson(jsonData) {
    if (typeof jsonData === 'string') {
      try {
        jsonData = JSON.parse(jsonData);
      } catch (e) {
        console.error('Unable to parse JSON data', e);
        return [];
      }
    }

    if (jsonData && typeof jsonData === 'object') {
      return Object.keys(jsonData).map(key => {
        return { key: key, value: jsonData[key] };
      });
    }
    return [];
  },
  
  },


}
</script>