<template>
  <div class="content-wrapper container-xxl p-0">
    <div class="content-header row"></div>
    <div class="content-body">
      <div class="d-flex justify-content-center">
        <b-spinner
          variant="success"
          label="Spinning"
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>

      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in appid" :key="item">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-end mt-1">
                <div class="uom-heading">
                  <h4 class="fw-bolder">My Facebook</h4>
                </div>
              </div>
              <app-collapse accordion>
                <app-collapse-item title="View Detail">
                  <div class="flex">
                    <p>
                      <span class="fw-bolder">App ID:</span>
                      <span class="fw-bolder"> {{ item.appId }}</span>
                    </p>
                    <p>
                      <span class="fw-bolder">App Secret:</span>
                      <span class="fw-bolder"> {{ item.appSecret }}</span>
                    </p>
                    <p>
                      <span class="fw-bolder">Page Name:</span>
                      <span class="fw-bolder"> {{ item.pageName }}</span>
                    </p>
                    <p>
                      <span class="fw-bolder">Page Id:</span>
                      <span class="fw-bolder"> {{ item.pageId }}</span>
                    </p>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-6 col-md-6" v-if="appid.length === 0">
          <div class="card">
            <div class="row">
              <div class="col-sm-12">
                <div class="card-body text-sm-end text-center ps-sm-0">
                  <a
                    href="javascript:void(0)"
                    v-b-modal.modal-add-facebook
                    class="stretched-link text-nowrap add-new-uom"
                  >
                    <span class="btn btn-primary mb-3">Add Facebook Connection</span>
                  </a>
                  <p class="mb-0">Add facebook connection, if it does not exist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add uom Modal -->
    <b-modal
      id="modal-add-facebook"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Add new uom"
      hide-footer="true"
    >
      <div class="modal-body px-5 pb-5">
        <div class="text-center mb-4">
          <h1 class="uom-title">Add Facebook Connection</h1>
        </div>
        <!-- Add uom form -->
        <form id="addUomForm" class="row" onsubmit="return false">
          <div class="col-12">
            <label class="form-label" for="pageId">Page Id</label>
            <input
              type="text"
              id="pageId"
              name="pageId"
              class="form-control"
              v-model="pageId"
              placeholder="Enter Page Id"
            />
          </div>
          <div class="col-12">
            <label class="form-label" for="pageName">Page Name</label>
            <input
              type="text"
              id="pageName"
              name="pageName"
              class="form-control"
              v-model="pageName"
              placeholder="Enter Page Name"
            />
          </div>
          <div class="col-12">
            <label class="form-label" for="userToken">User Token</label>
            <input
              type="text"
              id="userToken"
              name="userToken"
              class="form-control"
              v-model="userToken"
              placeholder="Enter User Token"
            />
          </div>
          <div class="col-12">
            <label class="form-label" for="appSecret">App Secret</label>
            <input
              type="text"
              id="appSecret"
              name="appSecret"
              class="form-control"
              v-model="appSecret"
              placeholder="Enter App Secret"
            />
          </div>
          <div class="col-12">
            <label class="form-label" for="app_Id">App Id</label>
            <input
              type="text"
              id="app_Id"
              name="app_Id"
              class="form-control"
              v-model="app_Id"
              placeholder="Enter App Id"
            />
          </div>

          <div class="col-12 text-center mt-2 d-flex justify-content-between">
            <b-button type="submit" class="btn btn-primary me-1" @click="addAppId">
              <b-spinner small v-if="loading" />Add Facebook Connection</b-button
            >
          </div>
        </form>
        <!--/ Add uom form -->
      </div>
    </b-modal>

  </div>
</template>

<script>
import { BSpinner, BFormTextarea, BButton, BModal } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  name: "Unit of measure",
  components: {
    BSpinner,
    BFormTextarea,
    BButton,
    BModal,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      ///edit
      EditUOMName: "",

      ///Add
      selectedItem: null,
      itemuoms: [],
      AddName: "",
      showSpinner: true,
      selectedUOM: [],

      ///Add
      pageId: "",
      pageName: "",
      userToken: "",
      appSecret: "",
      app_Id: "",
    };
  },
  computed: {
    ...mapGetters("marketingModule", {
      appid: "appid",
      loading: "loading",
    }),
  },

  methods: {
    showDetails(item) {
      this.selectedItem = item;
    },
    formSubmitted() {
      //this.$router.push('/product/list/')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Unit of measure Successfully Added",
          icon: "EditIcon",
          variant: "success",
        },
      }),
        this.$bvModal.hide("modal-add-facebook");
    },

    success() {
      this.$swal({
        title: "Facebook connection added!",
        text: "You have successfully added a Facebook connection!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data.errors);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    successUpdate() {
      this.$swal({
        title: "Facebook connection added!",
        text: "You have successfully updated a UOM!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    errorUpdate(data) {
      this.$swal({
        title: "Error!",
        text: data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(errors) {
      let msg = null;
      if (errors) {
        msg = "<div>";
        msg = "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
        return msg;
      }
    },
    ...mapActions("marketingModule", ["getAppIdAction", "postAppIdAction"]),

    successEdit() {
      this.$swal({
        title: "Facebook connection added!",
        text: "You have successfully edited a unit of measure!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    errorEdit() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    sendInfo(info) {
      this.selectedUOM = info;
      this.permissions.forEach((permission) => {
        if (info.permissions.includes(permission.value)) {
          this.selectedPermissions.push(permission.value);
        }
      });
    },

    updateUOM() {
      // let payload = {
      //     itemuomId: this.selectedUOM.id,
      //     name: this.selectedUOM.name
      // };
      let payload = this.selectedUOM;

      this.updateItemUOMAction(payload)
        .then(() => {
          this.successEdit();
          this.$bvModal.hide("modal-edit");
        })
        .catch(() => {
          this.errorEdit();
          this.$bvModal.hide("modal-edit");
        });
    },

    addAppId() {
      let payload = {
        pageId: this.pageId,
        pageName: this.pageName,
        userToken: this.userToken,
        appSecret: this.appSecret,
        app_Id: this.app_Id,
        platform: "facebook",
      };
      this.postAppIdAction(payload)
        .then((response) => {
          this.success();
          this.getAppIdAction();
          this.$bvModal.hide("modal-add-facebook");
        })
        .catch((response) => {
          this.error(response);
          this.getAppIdAction();
          this.$bvModal.hide("modal-add-facebook");
        });
    },
  },

  async mounted() {
    await this.getAppIdAction();
  },
};
</script>
