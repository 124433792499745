<template>
  <div class="content-wrapper container-xxl p-0">
    <div class="content-header row"></div>
    <div class="content-body">
      <div class="d-flex justify-content-center">
        <b-spinner
          variant="success"
          label="Spinning"
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>

      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in sortedItemUOM" :key="item">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-end mt-1 pt-25">
                <div class="uom-heading">
                  <h4 class="fw-bolder">{{ item.name }}</h4>
                  <a
                    href="javascript:;"
                    class="uom-edit-modal"
                    v-b-modal.modal-edit
                    @click="sendInfo(item)"
                  >
                    <small class="fw-bolder">Edit UOM</small>
                  </a>
                </div>
                <a href="javascript:void(0);" class="text-body"
                  ><i data-feather="copy" class="font-medium-5"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card">
            <div class="row">
              <div class="col-sm-12">
                <div class="card-body text-sm-end text-center ps-sm-0">
                  <a
                    href="javascript:void(0)"
                    v-b-modal.modal-add
                    class="stretched-link text-nowrap add-new-uom"
                  >
                    <span class="btn btn-primary mb-3">Add New Unit of measure</span>
                  </a>
                  <p class="mb-0">Add unit of measure, if it does not exist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add uom Modal -->
    <b-modal
      id="modal-add"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Add new uom"
      hide-footer="true"
    >
      <div class="modal-body px-5 pb-5">
        <div class="text-center mb-4">
          <h1 class="uom-title">Add New Unit of meaure</h1>
        </div>
        <!-- Add uom form -->
        <form id="addUomForm" class="row" onsubmit="return false">
          <div class="col-12">
            <label class="form-label" for="modalUOMName">Unit of measure</label>
            <input
              type="text"
              id="modalUOMName"
              name="modalUOMName"
              class="form-control"
              v-model="AddName"
              placeholder="Enter Unit of measure name"
            />
          </div>

          <div class="col-12 text-center mt-2 d-flex justify-content-between">
            <b-button type="submit" class="btn btn-primary me-1" @click="addUOM">
              <b-spinner small v-if="loading" />Add Unit of measure</b-button
            >
          </div>
        </form>
        <!--/ Add uom form -->
      </div>
    </b-modal>

    <!-- Edit uom Modal -->
    <b-modal
      id="modal-edit"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Edit uom"
      hide-footer="true"
    >
      <div class="modal-body px-5 pb-5">
        <div class="text-center mb-4">
          <h1 class="uom-title">Edit this uom</h1>
        </div>
        <!-- Add uom form -->
        <form id="editUOMForm" class="row" onsubmit="return false">
          <div class="col-12 my-1">
            <label class="form-label" for="modalUOMName">UOM Name</label>
            <input
              type="text"
              id="modalUOMName"
              name="modalUOMName"
              class="form-control"
              placeholder="Enter Unit of measure name"
              v-model="selectedUOM.name"
            />
          </div>

          <div class="col-12 my-1">
            <b-button variant="danger" @click="removeUOM(selectedUOM.id)">
              <b-spinner small v-if="loading" />Delete UOM
            </b-button>
          </div>

          <div class="col-12 text-center mt-2 d-flex justify-content-between">
            <b-button type="submit" class="btn btn-primary me-1" @click="updateUOM()">
              <b-spinner small v-if="loading" />Edit UOM</b-button
            >
          </div>
        </form>
        <!--/ Add UOM form -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BSpinner, BFormTextarea, BButton, BModal } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api-v1-config";

export default {
  name: "Unit of measure",
  components: {
    BSpinner,
    BFormTextarea,
    BButton,
    BModal,
  },

  data() {
    return {
      ///edit
      EditUOMName: "",

      ///Add
      itemuoms: [],
      AddName: "",
      showSpinner: true,
      selectedUOM: {},
    };
  },
  computed: {
    ...mapGetters("itemUomModule", {
      itemuom: "itemuom",
      loading: "loading",
    }),
    sortedItemUOM() {
     
      return this.itemuom.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  methods: {
    sendInfo(info) {
      this.selectedUOM = { ...info };
    },
    formSubmitted() {
      //this.$router.push('/product/list/')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Unit of measure Successfully Added",
          icon: "EditIcon",
          variant: "success",
        },
      }),
        this.$bvModal.hide("modal-add");
    },

    success() {
      this.$swal({
        title: "UOM Added!",
        text: "You have successfully added a UOM!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data.errors);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    successUpdate() {
      this.$swal({
        title: "UOM Updated!",
        text: "You have successfully updated a UOM!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    errorUpdate(data) {
      this.$swal({
        title: "Error!",
        text: data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(errors) {
      let msg = null;
      if (errors) {
        msg = "<div>";
        msg = "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
        return msg;
      }
    },
    ...mapActions("itemUomModule", [
      "getItemUOMListAction",
      "removeItemUOMAction",
      "updateItemUOMAction",
      "addItemUOMAction",
    ]),

    successEdit() {
      this.$swal({
        title: "Unit of measure edited!",
        text: "You have successfully edited a unit of measure!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    errorEdit() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    sendInfo(info) {
      this.selectedUOM = info;
      this.permissions.forEach((permission) => {
        if (info.permissions.includes(permission.value)) {
          this.selectedPermissions.push(permission.value);
        }
      });
    },

    updateUOM() {
      // let payload = {
      //     itemuomId: this.selectedUOM.id,
      //     name: this.selectedUOM.name
      // };
      let payload = this.selectedUOM;

      this.updateItemUOMAction(payload)
        .then(() => {
          this.successEdit();

          this.$bvModal.hide("modal-edit");
        })
        .catch(() => {
          this.errorEdit();
          this.$bvModal.hide("modal-edit");
        });
    },
    removeUOM(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeItemUOMAction(id)
            .then((response) => {
              this.getItemUOMListAction();
              console.log(response);
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Unit of measure has been deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              this.errorUpdate(error.response.data);
              console.log(error.response.data);
              this.$bvModal.hide("modal-edit");
              this.getItemUOMListAction();
            });

          this.$bvModal.hide("modal-edit");
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "UOM not deleted:)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.$bvModal.hide("modal-edit");
        }
      });
    },
    addUOM() {
      let payload = {
        name: this.AddName,
      };
      this.addItemUOMAction(payload)
        .then((response) => {
          this.success();
          this.getItemUOMListAction();
          this.AddName = "";
          this.$bvModal.hide("modal-add");
        })
        .catch((response) => {
          this.error(response);
          console.log(response);
          this.getItemUOMListAction();
          this.AddName = "";
          this.$bvModal.hide("modal-add");
        });
    },
  },

  async mounted() {
    await this.getItemUOMListAction()
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
