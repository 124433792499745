<template>
  <div class="content-wrapper container-xxl p-0">
    <div class="content-header row"></div>
    <div class="content-body">
      <div class="d-flex justify-content-center"></div>

      <b-row class="">
        <b-col
          xl="4"
          lg="6"
          md="6"
          v-for="(category, categoryIndex) in sortedCategories"
          :key="categoryIndex"
        >
          <b-card class="text-center">
            <b-card-title class="text-center">
              {{ category.title }}
            </b-card-title>
            <b-row>
              <b-col xl="12" v-if="category.export">
                <b-button
                  variant="secondary"
                  size="sm"
                  class="w-100"
                  @click="downloadFile(category.downloadLink, category.title)"
                >
                  <feather-icon icon="EyeIcon" /> Export Data
                </b-button>
              </b-col>

              <b-col xl="12" v-if="category.download">
                <b-button variant="info" size="sm" class="w-100 mt-1">
                  <a
                    :href="'/csv/' + csvFiles[category.title]"
                    download
                    target="_blank"
                    class="text-white"
                  >
                    <feather-icon icon="EyeIcon" /> View Example
                  </a>
                </b-button>
              </b-col>
              <b-col xl="12" v-if="category.download">
                <b-button
                  variant="success"
                  size="sm"
                  class="w-100 mt-1"
                  @click="open_file(categoryIndex)"
                >
                  <feather-icon icon="UploadCloudIcon" /> Upload File
                </b-button>
                <input
                  :id="'input_file_' + categoryIndex"
                  type="file"
                  ref="mediaInput"
                  class="d-none"
                  accept=".csv"
                  @change="handleMediaUpload($event, categoryIndex)"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BFormTextarea,
  BButton,
  BModal,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BImg,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api-v2-config";

export default {
  name: "Upload Center",
  components: {
    BSpinner,
    BFormTextarea,
    BButton,
    BModal,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BImg,
  },

  data() {
    return {
      ///edit
      EditUOMName: "",
      user: [],
      tenant: "",

      ///Add
      itemuoms: [],
      AddName: "",
      showSpinner: true,
      selectedUOM: [],
      categories: [
        {
          title: "Product Data",
          icon: "product",
          download: true,
          exampleLink: "",
          downloadLink: "products/download",
          uploadLink: "",
          export: true,
          uploadedFiles: [],
        },
        {
          title: "Category Data",
          icon: "categories",
          download: true,
          export: true,
          downloadLink: "itemcategories/download",
          exampleLink: "",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Raw Material Data",
          icon: "rawmaterials",
          export: true,
          download: true,
          downloadLink: "rawmaterials/download",
          exampleLink: "",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Customer Data",
          icon: "customer",
          download: true,
          export: true,
          exampleLink: "",
          downloadLink: "customers/download",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "UOM Data",
          icon: "UOM",
          download: true,
          export: false,
          exampleLink: "",
          downloadLink: "UOM/download",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Labour Data",
          icon: "Labour",
          exampleLink: "",
          export: true,
          download: false,
          uploadLink: "",
          downloadLink: "labours/download",
          uploadedFiles: [],
        },
        {
          title: "Equipment Data",
          icon: "Equipment",
          download: false,
          exampleLink: "",
          export: true,
          uploadLink: "",
          downloadLink: "equipments/download",
          uploadedFiles: [],
        },
        {
          title: "Supplier Data",
          icon: "Supplier",
          exampleLink: "",
          download: true,
          export: true,
          uploadLink: "",
          downloadLink: "suppliers/download",
          uploadedFiles: [],
        },
        {
          title: "Invoice Data",
          icon: "Invoice",
          export: true,
          exampleLink: "",
          download: false,
          uploadLink: "",
          downloadLink: "invoices/download",
          uploadedFiles: [],
        },
        {
          title: "Proforma Data",
          icon: "Proforma",
          export: true,
          download: false,
          exampleLink: "",
          downloadLink: "orders/download",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Lead Data",
          icon: "Lead",
          exampleLink: "",
          uploadLink: "",
          export: true,
          download: false,
          downloadLink: "leads/download",
          uploadedFiles: [],
        },
        {
          title: "Purchase Order Data",
          icon: "Purchase Order",
          download: false,
          export: true,
          exampleLink: "",
          downloadLink: "purchaseorders/download",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Warehouse Data",
          icon: "Warehouse",
          download: true,
          export: true,
          downloadLink: "warehouses/download",
          exampleLink: "",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Job Card Data",
          icon: "Jobcard",
          export: true,
          download: false,
          downloadLink: "jobcards/download",
          exampleLink: "",
          uploadLink: "",
          uploadedFiles: [],
        },
        {
          title: "Transaction Data",
          icon: "Transaction",
          download: false,
          export: true,
          downloadLink: "inventorytransactions/download",
          exampleLink: "",
          uploadLink: "",
          uploadedFiles: [],
        },
      ],
      csvFiles: {
        "Product Data": "Product.csv",
        "Category Data": "Category.csv",
        "Raw Material Data": "RawMat.csv",
        "UOM Data": "UOM.csv",
        "Customer Data": "Customernew.csv",
        "Warehouse Data": "warehouse.csv",
        "Supplier Data": "supplier.csv",
      },
    };
  },
  computed: {
    sortedCategories() {
      return this.categories.sort((a, b) => {
        return b.download - a.download;
      });
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userData"));

    this.tenant = localStorage.getItem("userTenant");
  },

  methods: {
    async downloadFile(downloadLink, categoryTitle) {
      this.$swal({
        title: "Downloading...",
        text: "Please wait while the file is being downloaded.",
        didOpen: () => {
          this.$swal.showLoading();
        },
        allowOutsideClick: false,
      });
      try {
        const response = await api.get(downloadLink, {
          responseType: "blob",
        });
        let filename = `${categoryTitle}_${new Date().toISOString()}.csv`;
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this.$swal.close();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "There was a problem downloading the file.",
          footer: "Try again later",
        });
      }
    },
    open_file(categoryIndex) {
      const input = this.$refs.mediaInput[categoryIndex];
      if (input) {
        input.click();
      }
    },
    async handleMediaUpload(event, categoryIndex) {
      this.$swal({
        title: "Uploading...",
        text: "Please wait while the file is being uploaded.",
        didOpen: () => {
          this.$swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const input = event.target;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
          const base64String = reader.result.split("base64,")[1];
          const payload = {
            name: this.tenant,
            extension: "csv",
            data: base64String,
            user: this.user.id,
          };

          try {
            let response;
            switch (this.categories[categoryIndex].title) {
              case "Product Data":
                response = await api.post("uploads/importproduct", payload);
                break;
              case "Category Data":
                response = await api.post("uploads/importcategory", payload);
                break;
              case "Raw Material Data":
                response = await api.post("uploads/importrawmaterials", payload);
                break;
              case "UOM Data":
                response = await api.post("uploads/importuom", payload);
                break;
              case "Customer Data":
                response = await api.post("uploads/importleadclient", payload);
                break;
              case "Warehouse Data":
                response = await api.post("uploads/importwarehouse", payload);
                break;
              case "Supplier Data":
                response = await api.post("uploads/importsuppliers", payload);
                break;
              default:
                console.log("Unknown category");
            }
            this.$swal({
              icon: "success",
              title: "Upload done...",
              text: "Your file has been uploaded",
            }).then(() => {
              window.location.reload();
            });
          } catch (error) {
            this.$swal({
              icon: "error",
              title: "Upload failed...",
              text: error.response.data.message ?? "There was a problem uploading the file.",
              footer: "Try again later",
            }).then(() => {
              window.location.reload();
            });
          }
        };
          reader.readAsDataURL(file);
          //this.categories[categoryIndex].uploadedFiles = Array.from(input.files);
      }
    },
  },
};
</script>
