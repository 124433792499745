<template>
  <b-card>
    <div class="d-flex justify-content-center">
      <b-spinner variant="success" label="Spinning" v-if="showSpinner" style="width: 3rem; height: 3rem;"></b-spinner>
    </div>
    <!-- media -->
    <b-media no-body v-if="!showSpinner">
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded v-if="generalData.imageUrl && imageFile === null"
            :src="getUrlImage(generalData.imageUrl)" height="80" />
        </b-link>
        <b-link>
          <b-img ref="previewEl" rounded v-if="imageFile != null && imageFile.length != 0" :src="imageFile" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <label for="file-upload" class="custom-file-upload text-white mb-75 mr-75">
          Upload
        </label>
        <input id="file-upload" type="file" @change="showImagePreview($event)" accept="image/.jpg, .jpeg" />

        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
          v-on:click="clearImage">
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="generalData.userName" placeholder="Username" name="username" readonly />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="First Name" label-for="account-name">
            <b-form-input v-model="generalData.firstName" name="name" placeholder="First Name" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Last Name" label-for="account-name">
            <b-form-input v-model="generalData.lastName" name="name" placeholder="Last Name" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="generalData.email" name="email" placeholder="Email" readonly />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Phone Number" label-for="account-Number">
            <b-form-input name="Number" placeholder="Phone Number" v-model="generalData.phoneNumber" />
          </b-form-group>
        </b-col>
        <!-- alert -->
        <b-col cols="12" class="mt-75">
          <b-alert show variant="success" class="mb-50" v-if="generalData.isActive">
            <h4 class="alert-heading">
              This user is currently active.
            </h4>
          </b-alert>

          <b-alert show variant="warning" class="mb-50" v-if="!generalData.isActive">
            <h4 class="alert-heading">
              This user is currently inative.
            </h4>
          </b-alert>
        </b-col>
        <!--/ alert -->
        <!-- alert -->
        <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50" v-if="!generalData.emailConfirmed">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
          </b-alert>
          <b-alert show variant="success" class="mb-50" v-if="generalData.emailConfirmed">
            <h4 class="alert-heading">
              Your email is confirmed.
            </h4>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" @click="UpdateUser()">
            Save changes
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
            @click.prevent="resetForm">
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import api from "@/api/api-v1-config";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      profileFile: null,
      imageFile: null,
      input: null,
      isImageUploading: false,
      token: localStorage.getItem('accessToken'),
      showSpinner: false,
    }
  },
  methods: {
    // success
    success() {
      this.$swal({
        title: 'Changes Done!',
        text: 'You have successfully updated your profile.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(error) {
      this.$swal({
        title: 'Error!',
        text: error,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async UpdateUser() {
      this.showSpinner = true;

      if (this.imageFile === null) {
        let data = {
          id: this.generalData.id,
          firstName: this.generalData.firstName,
          lastName: this.generalData.lastName,
          phoneNumber: this.generalData.phoneNumber,
          email: this.generalData.email,
        }
        api.defaults.headers.Authorization = 'Bearer ' + this.token;
        await api.put('personal/profile', data)

          .then(response => {
            this.showSpinner = false;
            this.success()
          })
          .catch(error => {
            this.showSpinner = false;
            console.log(error)
            this.error(error.response.data.message)

          })
      }
      else {
        let data = {
          id: this.generalData.id,
          firstName: this.generalData.firstName,
          lastName: this.generalData.lastName,
          phoneNumber: this.generalData.phoneNumber,
          email: this.generalData.email,
          image: {
            name: this.generalData.firstName,
            extension: ".jpg",
            data: this.imageFile
          },
          deleteCurrentImage: true

        }
        api.defaults.headers.Authorization = 'Bearer ' + this.token;
        await api.put('personal/profile', data)

          .then(response => {
            this.showSpinner = false;

            this.success()

          })
          .catch(error => {
            this.showSpinner = false;
            console.log(error)
            this.error(error.response.data.message)

          })
      }

    },
    getUrlImage(name) {
      const url = process.env.VUE_APP_MAIN_URL;
      return url + name;
    },
    showImagePreview(event) {
      this.input = event.target;
      if (this.input.files && this.input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageFile = e.target.result;
        };
        reader.readAsDataURL(this.input.files[0]);
      }
    },
    uploadImage() {
      this.isImageUploading = true;
      setTimeout(() => {
        console.log(this.imageFile);
        this.isImageUploading = false;
        this.clearImage();
      }, 3000);
    },
    clearImage() {
      this.imageFile = null;
      this.input = null;
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.custom-file-upload {
  padding: 5px 11px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #9FC54C;
}
</style>
